export default {
  ["receive"]: "Terima",
  ["mining_pool"]: "Kolam Penambangan",
  ["account"]: "Akun",
  ["pool_data"]: 'Data kolam renang',
  ["total_output"]: "Total Output",
  ["valid_node"]: "Simpul yang Valid",
  ["participant"]: "Peserta",
  ["user_revenue"]: "Pendapatan Pengguna",
  ["activity"]: "Aktivitas",
  ["mining"]: "Pertambangan",
  ["liquidity"]: "likuiditas pendapatan pertambangan",
  ["user_output"]: "Keluaran Pengguna",
  ["address"]: "Alamat",
  ["quantity"]: "Kuantitas",
  ["help_center"]: "Pusat Bantuan",
  ["hep_center_tip"]: "semoga membantu Anda",
  ["question1"]: "Bagaimana saya bisa bergabung?",
  ["answer1"]: "Untuk berpartisipasi dalam penambangan likuiditas yang tidak merusak dan tidak dijamin, Anda perlu membayar biaya penambang ETH untuk menerima voucher, dan alamat dompet ETH hanya perlu diklaim sekali. Secara otomatis membuka izin penambangan setelah berhasil.",
  ["question2"]: "Bagaimana cara saya menarik uang?",
  ["answer2"]: "Anda dapat mengonversi koin yang diproduksi setiap hari menjadi USDC, dan kemudian melakukan penarikan. Penarikan USDC akan secara otomatis dikirim ke alamat dompet yang Anda tambahkan ke node, dan alamat lain tidak didukung.",
  ["question3"]: "Cara menghitung pendapatan?",
  ["answer3"]: "Ketika Anda berhasil bergabung, smart contract mulai menghitung alamat Anda melalui node dan mulai menghitung pendapatan.",
  ["audit_report"]: "Audit Report",
  ["audit_report_tip"]: "kami memiliki laporan audit yang aman",
  ["partner"]: "Mitra",
  ["partner_tip"]: "mitra bisnis kami",
  ["my_account"]: "Akun saya",
  ["mining_status"]: "Mining Status",
  ["wallet_balance"]: "Saldo Dompet",
  ["exchangeable"]: "Dapat ditukar",
  ["withdrawable"]: "Dapat ditarik",
  ["share_dividends"]: "Dividen Saham",
  ["my_level"]: "Tingkat saya",
  ["level"]: "Tingkat",
  ["hash_rate"]: "Tingkat hash",
  ["output_interest"]: "Tingkat bunga output",
  ["output_interest_rate"]: "Tingkat bunga output",
  ["output_interest_cycle"]: "Siklus bunga keluaran",
  ["level_details"]: "Detail level",
  ["pair_price"]: "Harga pasangan",
  ["exchange"]: "Pertukaran",
  ["withdraw"]: "Menarik diri",
  ["redeem_all"]: "Tebus semua",
  ["total_balance"]: "Total saldo",
  ["record"]: "Catatan",
  ["shared"]: "Bersama",
  ["output"]: "Keluaran",
  ["back"]: "Kembali",
  ["no-data"]: "Tidak ada data",
  ["received"]: "Diterima",
  ["transfer"]: "Transfer",
  ["share_friends"]: "Berbagi teman",
  ["my_share_link"]: "Tautan berbagi saya",
  ["copy"]: "Tautan berbagi saya",
  ["share_tip"]: "Kirim tautan undangan Anda, teman-teman bergabung dengan node melalui tautan Anda, dan Anda akan mendapatkan hadiah token yang murah hati",
  ["join_tip"]: "Anda telah bergabung",
  ["copySucess"]: "Tautan telah disalin",
  "apply_mining_pool_rewards": "Terapkan hadiah kolam penambangan",
  "standard": "Standar",
  "required": "Diperlukan",
  "countdown": "Hitung mundur",
  "host_day": "Hari",
  "reward": "Hadiah",
  ["i_know"]: "Saya tahu",
  ["popup_miniing_title"]: "Terapkan Hadiah Kolam Pertambangan",
  ["popup_miniing_conn1"]: "Kontrak Pintar ERC-20",
  ["popup_miniing_conn2"]: "Kolam pertambangan kontrak pintar ERC-20 akan segera memulai acara berbagi ETH berikutnya. Ajukan untuk bergabung sekarang. Setelah acara dimulai, penyedia likuiditas dapat berbagi berbagai hadiah.",
  ["popup_miniing_btn"]: "Terapkan Hadiah",
  "recharge": "Isi Ulang",
  "recharge_currency": "Alamat deposit",
  "recharge_scan code transfer": "Simpan QR",
  "recharge_amount": "Jumlah deposit",
  "please enter": "Silakan masukkan",
  "recharge_upload": "Klik untuk mengunggah gambar",
  "confirm": "Konfirmasi",
  "rachange_link_coinbase": "Saluran isi ulang resmi Coinbase",
  "rachange_link_binance": "Saluran isi ulang resmi Binance",
  "rachange_link_huobi": "Saluran isi ulang resmi Huobi",
  "cancel": "Batal",
  "addressCopySuccess": "Alamat telah disalin",
  "recharge_record": "Riwayat Isi Ulang",
  "all": "Semua",
  "processing": "Menunggu",
  "credited": "Berhasil",
  "fail": "Gagal",
  "amount": "Jumlah",
  "state": "Status",
  "time": "Waktu",
  "record_tip": "Catatan",
  "activity-top-title": "ETH Airdrop Hadiah",
  "activity-top-big-title": "Hadiah 1 Juta ETH Airdrop Telah Tiba",
  "activity-top-tip": "Selesaikan tugas sederhana di halaman node pool proyek penghasilan Defi Anda untuk mendapatkan bagian dari hadiah airdrop besar",
  "activity-end": "Kegiatan Berakhir",
  "activity-join": "Ikuti Kegiatan"
}