export default {
  "receive": "Recibir",
  "mining_pool": "Pool de Minería",
  "account": "Cuenta",
  "pool_data": "Datos del Pool",
  "total_output": "Producción Total",
  "valid_node": "Nodo Válido",
  "participant": "Participante",
  "user_revenue": "Ingresos del Usuario",
  "activity": "Actividad",
  "mining": "Minería",
  "liquidity": "ingresos por minería de liquidez",
  "user_output": "Producción del Usuario",
  "address": "Dirección",
  "quantity": "Cantidad",
  "help_center": "Centro de Ayuda",
  "hep_center_tip": "espero que te ayude",
  "question1": "¿Cómo puedo unirme?",
  "answer1": "Para participar en la minería de liquidez sin pérdidas y sin garantías, debes pagar una tarifa de minero de ETH para recibir el comprobante, y una dirección de billetera ETH solo necesita reclamarse una vez. Después del éxito, los permisos de minería se abrirán automáticamente.",
  "question2": "¿Cómo retiro dinero?",
  "answer2": "Puedes convertir las monedas producidas diariamente en USDC y luego iniciar un retiro. El retiro de USDC se enviará automáticamente a la dirección de la billetera que agregaste al nodo y no se admiten otras direcciones.",
  "question3": "¿Cómo se calcula el ingreso?",
  "answer3": "Cuando te unes con éxito, el contrato inteligente comienza a calcular tu dirección a través del nodo y comienza a calcular el ingreso.",
  "audit_report": "Informe de Auditoría",
  "audit_report_tip": "tenemos un informe de auditoría seguro",
  "partner": "Socio",
  "partner_tip": "nuestro socio comercial",
  "my_account": "Mi Cuenta",
  "mining_status": "Estado de Minería",
  "wallet_balance": "Saldo de la Billetera",
  "exchangeable": "Intercambiable",
  "withdrawable": "Retirable",
  "share_dividends": "Dividendos de Acciones",
  "my_level": "Mi Nivel",
  "level": "Nivel",
  "hash_rate": "Tasa de Hash",
  "output_interest": "Intereses de Producción",
  "output_interest_rate": "Tasa de Intereses de Producción",
  "output_interest_cycle": "Ciclo de Intereses de Producción",
  "level_details": "Detalles del Nivel",
  "pair_price": "Precio del Par",
  "exchange": "Intercambiar",
  "withdraw": "Retirar",
  "redeem_all": "Canjear Todo",
  "total_balance": "Saldo Total",
  "record": "Registro",
  "shared": "Compartido",
  "output": "Producción",
  "back": "Atrás",
  "no-data": "Sin Datos",
  "received": "Recibido",
  "transfer": "Transferir",
  "share_friends": "Compartir con Amigos",
  "my_share_link": "Mi Enlace de Compartir",
  "copy": "Copiar",
  "share_tip": "Envía tu enlace de invitación, los amigos se unen al nodo a través de tu enlace y recibirás generosas recompensas en tokens",
  "join_tip": "Te has unido",
  "copySuccess": "Enlace copiado",
  "apply_mining_pool_rewards": "Solicitar Recompensas del Pool de Minería",
  "standard": "Estándar",
  "required": "Requerido",
  "countdown": "Cuenta Regresiva",
  "host_day": "Día",
  "reward": "Recompensa",
  "i_know": "Lo sé",
  "popup_miniing_title": "Solicitar Recompensas del Pool de Minería",
  "popup_miniing_conn1": "Contrato Inteligente ERC-20",
  "popup_miniing_conn2": "El pool de minería con contrato inteligente ERC-20 está a punto de comenzar el próximo evento de distribución de ETH. Solicita unirte ahora. Después de que comience el evento, los proveedores de liquidez pueden compartir múltiples recompensas",
  "popup_miniing_btn": "Solicitar Recompensas",
  "recharge": "Recargar",
  "recharge_currency": "Dirección de depósito",
  "recharge_scan code transfer": "Guardar QR",
  "recharge_amount": "Cantidad del depósito",
  "please enter": "Por favor, ingrese",
  "recharge_upload": "Haga clic para subir imágenes",
  "confirm": "Confirmar",
  "rachange_link_coinbase": "Canal oficial de recarga de Coinbase",
  "rachange_link_binance": "Canal oficial de recarga de Binance",
  "rachange_link_huobi": "Canal oficial de recarga de Huobi",
  "cancel": "Cancelar",
  "addressCopySuccess": "Dirección copiada",
  "recharge_record": "Historial de Recargas",
  "all": "Todo",
  "processing": "Pendiente",
  "credited": "Éxito",
  "fail": "Fallido",
  "amount": "Cantidad",
  "state": "Estado",
  "time": "Hora",
  "record_tip": "Nota",
  "activity-top-title": "Recompensas de Airdrop ETH",
"activity-top-big-title": "Llegan 1 millón de Recompensas de Airdrop ETH",
"activity-top-tip": "Complete tareas simples en la página del nodo del pool de minería de su proyecto de rendimiento DeFi para compartir una enorme recompensa de airdrop.",
"activity-end": "Actividad finalizada",
"activity-join": "Unirse a la actividad",
}