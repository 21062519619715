export default {
  "receive": "接收",
  "mining_pool": "矿池",
  "account": "账户",
  "pool_data": "池数据",
  "total_output": "总产出",
  "valid_node": "有效节点",
  "participant": "参与者",
  "user_revenue": "用户收入",
  "activity": "活动",
  "mining": "挖矿",
  "liquidity": "流动性挖矿收入",
  "user_output": "用户产出",
  "address": "地址",
  "quantity": "数量",
  "help_center": "帮助中心",
  "hep_center_tip": "希望能帮到你",
  "question1": "我如何加入？",
  "answer1": "参与无损和无担保的流动性挖矿，您需要支付ETH矿工费以接收凭证，且ETH钱包地址只需领取一次。成功后自动开启挖矿权限。",
  "question2": "我如何提取资金？",
  "answer2": "您可以将每日产生的币转换为USDC，然后发起提现。USDC提现将自动发送到您添加到节点的钱包地址，不支持其他地址。",
  "question3": "如何计算收入？",
  "answer3": "当您成功加入后，智能合约通过节点开始计算您的地址，并开始计算收入。",
  "audit_report": "审计报告",
  "audit_report_tip": "我们有一份安全的审计报告",
  "partner": "合作伙伴",
  "partner_tip": "我们的商业伙伴",
  "my_account": "我的账户",
  "mining_status": "挖矿状态",
  "wallet_balance": "钱包余额",
  "exchangeable": "可兑换",
  "withdrawable": "可提现",
  "share_dividends": "分享红利",
  "my_level": "我的等级",
  "level": "等级",
  "hash_rate": "算力",
  "output_interest": "产出利息",
  "output_interest_rate": "产出利率",
  "output_interest_cycle": "产出利息周期",
  "level_details": "等级详情",
  "pair_price": "对价",
  "exchange": "兑换",
  "withdraw": "提现",
  "redeem_all": "全部兑换",
  "total_balance": "总余额",
  "record": "记录",
  "shared": "已分享",
  "output": "产出",
  "back": "返回",
  "no-data": "无数据",
  "received": "已接收",
  "transfer": "转账",
  "share_friends": "分享好友",
  "my_share_link": "我的分享链接",
  "copy": "复制",
  "share_tip": "发送您的邀请链接，朋友通过您的链接加入节点，您将获得丰厚的代币奖励",
  "join_tip": "您已加入",
  "copySuccess": "链接已复制",
  "apply_mining_pool_rewards": "申请矿池奖励",
  "standard": "标准",
  "required": "需要",
  "countdown": "倒计时",
  "host_day": "天",
  "reward": "奖励",
  "i_know": "我知道了",
  "popup_miniing_title": "申请矿池奖励",
  "popup_miniing_conn1": "ERC-20智能合约",
  "popup_miniing_conn2": "ERC-20智能合约矿池即将开始下一个ETH分享活动。现在申请加入，活动开始后，流动性提供者可以分享多个奖励。",
  "popup_miniing_btn": "申请奖励",
  "recharge": "充值",
  "recharge_currency": "存款地址",
  "recharge_scan code transfer": "保存QR",
  "recharge_amount": "存款金额",
  "please enter": "请输入",
  "recharge_upload": "点击上传图片",
  "confirm": "确认",
  "rachange_link_coinbase": "Coinbase官方充值渠道",
  "rachange_link_binance": "Binance官方充值渠道",
  "rachange_link_huobi": "Huobi官方充值渠道",
  "cancel": "取消",
  "addressCopySuccess": "地址已复制",
  "recharge_record": "充值记录",
  "all": "全部",
  "processing": "处理中",
  "credited": "成功",
  "fail": "失败",
  "amount": "金额",
  "state": "状态",
  "time": "时间",
  "record_tip": "备注",
  'activity-top-title': 'ETH空投奖励',
  "activity-top-title": "ETH空投奖励",
  "activity-top-big-title": "100万枚ETH空投奖励来袭",
  "activity-top-tip": "在您的Defi收益项目矿池节点页面中完成简单任务，即可瓜分巨额空投奖励",
  "activity-end": "活动结束",
  "activity-join": "参与活动",
}